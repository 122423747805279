import mixpanel from 'mixpanel-browser'
mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN);
const development = process.env.NODE_ENV === 'development'

export const _trackEvent = (event, params) => {

    if (development) {
        return
    }
    mixpanel.track(event, params);
}

export const TRACKING_EVENTS = {
    STORE_VISITED: 'Store Visited',
    PRODUCT_ADDED_TO_CART: 'Shopper Product Added To Cart'
}